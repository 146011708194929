.button-khq-submit.button-footer{
    display: inline-block !important;
    width: 100% !important;
    height: 5% !important;
}

.react-dropdown-select{
    height: calc(1.5em + 0.75rem + 2px) !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    padding: 0.375rem 0.75rem !important;
}

svg{
    color: #000000;
}

.btn.dropdown-toggle.btn-white.dropdown-toggle.btn.btn-primary{
    height: 35px;
}

input.placeholder{
    border-color: transparent;
    border-bottom-color: transparent !important;
    width: 100%;
    padding: 0px;
}